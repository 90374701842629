.header-container {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.thead-gray {
  background-color: grey;
}

.p-5 {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hero-section {
  height: 50vh;
  background-color: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.hero-title {
  font-size: 4rem;
  font-weight: bold;
  animation: slideIn 2s ease-out;
}

.hero-subtitle {
  font-size: 1.5rem;
  color: #6c757d;
  animation: fadeIn 3s ease-in;
}

.copy-text {
  font-size: 1rem; 
  line-height: 1.5;
  padding: 10px; 
  word-wrap: break-word; 
  color: #333; 
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.shadow {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
@media (max-width: 1000px) {
  .product-img {
    height: 10em;
    width: 10em;
  }
}
@media (min-width: 1000px) {
  .product-img {
    height: 20%;
    width: 20%;
  }
}
.scrollable-content {
  overflow-y: auto;
  min-height: 80vh;
  max-height: 80vh;
}

.navbar-brand img {
  width: 10%;
}

/*Packagepicker*/
/* .container-fluid {
  background-color: #f8f9fa;
} */
.card {
  /* background-color: #f8f9fa; */
  border: 1px solid #ced4da;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
  color: #007bff;
}

.card-body {
  padding: 1.5rem;
}

.btn {
  transition: all 0.3s ease;
}
.logo-icon-home{
  width: 7%;
}
.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  background-color: #007bff;
  color: #fff;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  background-color: #6c757d;
  color: #fff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1;
  border-radius: 20px;
}

.card-body {
  position: relative;
  z-index: 2;
  color: #ffffff;
}

/* .card-title,
.card-text,
.lead,
.text-muted {
  color: #ffffff;
} */
