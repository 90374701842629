/* Login.css */
.custom-input {
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 10px;
  font-size: 1.2em;
  width: 100%;
  margin-bottom: 1em;
}

.custom-input:focus {
  outline: none;
  border-bottom-color: #66d1be;
}

.forgot-password {
  display: block;
  text-align: right;
  margin-top: -10px;
  margin-bottom: 20px;
  color: black;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
}
.btn-primary {
  background-color: #66d1be;
  border-color: white;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 1.2rem;
  width: 50%;
  color: white;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.btn-primary:hover {
  background-color: #5ab1a0;
  border-color: white;
  color: white;
}

/*The */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.underline-text {
  margin-top: 4px;
  text-decoration: underline;
}

.fade-in.active {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 768px) {
  .modal-body img {
    height: 30vh;
  }
}

.whatsapp-float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;

  color: #25d366;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  box-shadow: 0px 4px 10px rgba(25, 155, 21, 0.15);
  z-index: 100;
  transition: background-color 0.3s, transform 0.3s;
}

.whatsapp-float:hover {
  background-color: #25d366;
  transform: scale(1.1);
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-text {
  animation: fadeIn 2s ease-in-out;
}
@media (max-width: 767.98px) {
  .whatsapp-float {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    bottom: 15px;
    right: 15px;
  }
}
