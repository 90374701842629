.package-carousel .carousel-control-prev,
.package-carousel .carousel-control-next {
  display: none;
}

.card {
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
@media (max-width: 992px) {
  .package-carousel .carousel-inner {
    background-color: #fff;
    background-size: cover;
    background-position: center;
  }
}

.accordian-txt {
  background-color: dark;
  color: white;
}
/* Packages.css */
.progress-circle-container {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 10px auto;
}

.progress-circle {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke: #5ab1a0;
  stroke-width: 3.8;
  stroke-linecap: round;
  transition: stroke-dasharray 2s ease;
}

.backup-hours {
  border: 2px solid #5ab1a0;
  border-radius: 20px;
  padding: auto;
}
.icon-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-circle svg {
  color: #5ab1a0;
}

.card {
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.custom-button-group .custom-button {
  background-color: #fff;
  border: 2px solid #5ab1a0;
  color: rgb(0, 0, 0);
  font-weight: bold;
  border-radius: 0;
  padding: 10px 20px;
  margin: 0;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 20px;
}

.custom-button-group .custom-button:not(:last-child) {
  border-right: none;
}

.custom-button-group .custom-button:last-child {
  border-left: none;
}

.custom-button-group .custom-button.active {
  background-color: #000000;
  border-color: #000000;
}

.custom-button-group .custom-button:hover {
  background-color: #cddded;
  border-color: #000000;
}

.appliance-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.appliance-list li {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #ccc;
  margin-right: 0.5rem;
}

.appliance-list li:last-child {
  border-bottom: none;
}

.appliance-list li .fa-icon {
  margin-right: 0.5rem;
}

.appliance-txt {
  margin-left: 1.5rem;
}

.price-color {
  color: rgb(156, 156, 236);
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 47%;
  width: 100%;
  transform: translateY(-50%);
  z-index: 999;
}

.carousel-ctrl-prev, .carousel-ctrl-next {
  background: rgba(0, 0, 0, 0.2);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  z-index: 999;
  font-size: 30px;
}
